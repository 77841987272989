import axios from 'axios';

//const API_URL = "http://localhost:8080/ors/api/email";
const API_URL = "https://api.onlineroadservices.com/api/email";

const sendEmail =async (formData) => {
    try {
        const response = await axios.post(API_URL+'/contact', formData, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error Submit Details:', error.response ? error.response.data : error.message);
        throw new Error(error.response ? error.response.data : 'Sorry Failed to submit details, please try again.');
    }
}

const EmailService ={
    sendEmail
}

export default EmailService;