import React, { useEffect, useState } from 'react';
import { Container, Card, Button, Modal, Row, Col, Tabs, Tab } from 'react-bootstrap';
import PlanService from '../../service/PlanService';
import ComponenetHeader from '../common/ComponenetHeader';
import { Alert, Spinner } from "react-bootstrap";
import EmailService from '../../service/EmailService';

const Plans = () => {
  const [plans, setPlans] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [key, setKey] = useState('');
  const [formData, setFormData] = useState({name: '', email: '', phone: '' });
  const [message, setMessage] = useState({ text: '', variant: '' });
  const [loading, setLoading] = useState(false); 

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await PlanService.getAllPlans();
      setPlans(response);
      if (response.length > 0) {
        setKey(response[0].id); // Set the first plan's id as the default active tab
      }
    } catch (error) {
      console.error('Error fetching plans:', error);
    }
  };

  const handleShow = (plan) => {
    setSelectedPlan(plan);
    setShowModal(true);
  };

  const handleChange =(e) =>{
    const {name, value} = e.target;
    setFormData({ ...formData, [name]: value});
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true when the request starts
    setMessage({ text: '', variant: '' }); // Clear previous messages

    try {
       const response = await EmailService.sendEmail(formData);
       setMessage({ text: [response], variant: 'success' });
       setFormData({ name: '', email: '', phone: '' });
    } catch (error) {
       setMessage({ text: `Error: ${error.message}`, variant: 'danger' });
    } finally {
       setLoading(false); 
     }
  }
  

  const handleClose = () => setShowModal(false);
  return (<>
         <ComponenetHeader
           name={'Plans'}
        />

    <section className="plan-section">
      <Container>     
        <Row>
          <div className='plan_title'>
            <h2 className="text-center">Explore Our Plans</h2>
            <p className="text-center">Choose the perfect plan that fits your needs. You can opt for monthly or yearly <br />subscriptions for maximum flexibility.</p>
          </div>
        </Row>

        <Row className='mt-3 plan_tabs'>
          <Tabs
            id="plan-tabs"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-5"
          >
            {plans.map((plan) => (
              <Tab eventKey={plan.id} title={plan.name} key={plan.id}>

                <Col md={12} className="mb-4 px-0"> 
                  <Card className="text-start">
                    <Row className='mx-0'>
                      {plan.imageName && (
                        <Col md={3} className='p-0 align-content-around ad_banner'>
                          <Card.Img
                            variant="top"
                            src={plan.imageName}
                            alt={plan.name}
                          />
                        </Col>
                      )}

                      <Col md={plan.imageName ? 7 : 10}> 

                        <Card.Body className='p-0 planCard-style'>
                          <Row>
                            <Col md={12} className='planInfo-style px-3 py-2'> 
                              <div className='row'>
                                <div className='col-md-6'>
                                  <div className='cardText'>
                                    <span className='ad_miles'>0.0 Miles</span>
                                    <Card.Title>Your Company Name</Card.Title>
                                    <p className='ad_address mb-0'><i className="fas fa-location-dot"></i> company address, city, state</p>
                                    <p className='ad_email mb-0'><i className="fa fa-envelope"></i> contact@demo.com</p>
                                    <p className='ad_website mb-2'> 
                                      <i className="fa fa-globe"></i> <a href='#show' target="_blank" rel="noopener noreferrer">www.demo.com</a>
                                    </p>
                                  </div>
                                </div>

                                <div className='col-md-6'>
                                  <div className='ad_services'>
                                    <h6 className='ad_worktype'>Work Type : <b>Shop/Mobile</b></h6>
                                    <ul className='ad_serviceList'>
                                      <li>Road Service</li>
                                      <li>Electrical System</li>
                                      <li>New Tires Sales</li>
                                      <li>Fleet Acounts</li>
                                    </ul>
                                  </div>
                                </div>

                                <div className='col-md-12'>
                                  <div className="ad_description">
                                    <p className='mb-0'>{plan.description}</p>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Col>

                      <Col md={2} className='p-0'>
                        <div className="planPrice-style text-center align-content-around">
                          <h5>{plan.name} </h5> 
                          <h3>${plan.price}<span>Per Month</span></h3>
                          <button className='btn showNumber' type="button"><i className="fa fa-phone"></i> +123-456-7890</button>
                          <Button className='planBtn' variant="primary" onClick={() => handleShow(plan)}>Get {plan.name}</Button>
                        </div>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Tab>
            ))}
          </Tabs>
        </Row>

        {/* Modal for contact details */}
        <Modal className='quote_modal' show={showModal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Get A Quote</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedPlan && (<>
              
              <div className='form_content'>
              <p className='mb-4'>Thank you for your interest in our <b>{selectedPlan.name} plan</b>. We’re thrilled to offer you our exceptional 
                services and are here to help you every step of the way!</p>
              </div>
              
              <div className='quote_form'>
                <form onSubmit={handleSubmit}>
                  <Row>
                    <div className="col-md-6">
                      <div className="form-group cont_form">
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        placeholder="Your Name"
                        required
                        disabled={loading}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group cont_form">
                      <input
                        type="tel"
                        className="form-control"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        placeholder="Phone"
                        required
                        disabled={loading}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group cont_form">
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        placeholder="Email"
                        required
                        disabled={loading}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                    {message.text && (
                      <Alert variant={message.variant} className="mt-0 py-2 text-center"> 
                        {message.text}
                      </Alert>
                      )}
                      <div className="form_btn mt-1"> 
                        <button type="submit" className="btn w-100" disabled={loading}>
                          {loading ? (
                              <>
                                <Spinner animation="border" size="sm" role="status" className="me-2" />
                                Sending...
                              </>
                          ) : (
                              'Submit'
                          )}
                        </button>
                      </div>
                    </div>

                  </Row>
                </form>
              </div>
              
              </>
            )}
          </Modal.Body>
        </Modal>
      </Container>
    </section>

    </>
  );
};

export default Plans;