import axios from 'axios';

//const API_URL = "http://localhost:8080/ors/api/services"; 
const API_URL = "https://api.onlineroadservices.com/api/services";

const getServiceById = async (id, token) => {
    try {
        const response = await axios.get(`${API_URL}/${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error getting Service:', error.response ? error.response.data : error.message);
        throw new Error(error.response ? error.response.data : 'Failed to get Service');       
    }
};

const ServicesService = {
    getServiceById
};

export default ServicesService;