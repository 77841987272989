import axios from 'axios';

//const API_URL = "http://localhost:8080/ors/api/subcategories";
const API_URL = "https://api.onlineroadservices.com/api/subcategories";

const getSubcategoriesByCategory = async () => {
    try {
        const response = await axios.get(`${API_URL}/byCategoryAll/1`);
        return response.data;
    } catch (error) {
        throw new Error(error.response ? error.response.data : 'Failed to get Subcategory');       
    }
};

const SubcategoryService = {
    getSubcategoriesByCategory
};

export default SubcategoryService;