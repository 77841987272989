const API_URL = 'https://nationalusa.net/api/';

const getLocationByName = async (newLocation) => {
    try {
        const response = await fetch(`${API_URL}getlocationlistbyname?apikey=a1nm2o55l5&name=${newLocation}`);
        if (!response.ok) {
            throw new Error('Failed to fetch location data');
        }
        const data = await response.json();
        return data.response;
    } catch (error) {
        throw new Error(error.message || 'Failed to get location');
    }
};

const getLocationById = async (id) => {
    try {
        const response = await fetch(`${API_URL}getlocationbyid?apikey=a1nm2o55l5&id=${id}`);
        if (!response.ok) {
            throw new Error('Failed to fetch location data');
        }
        const data = await response.json();
        return data.response;
    } catch (error) {
        throw new Error(error.message || 'Failed to get location');
    }
};

const getCityList = async (countryId, stateId) => {
    try {
        const response = await fetch(`${API_URL}getcitylist?apikey=a1nm2o55l5&country=${countryId}&state=${stateId}`);
        if (!response.ok) {
            throw new Error('Failed to fetch location data');
        }
        const data = await response.json();
        return data.response;
    } catch (error) {
        throw new Error(error.message || 'Failed to get location');
    }
};

const getLocationByLocation = async (name) => {
    try {
        const response = await fetch(`${API_URL}getlocationbyname?apikey=a1nm2o55l5&name=${name}`);
        if (!response.ok) {
            throw new Error('Failed to fetch location data');
        }
        const data = await response.json();
        return data.response;
    } catch (error) {
        throw new Error(error.message || 'Failed to get location');
    }
};

const LocationService = {
    getLocationByName,
    getLocationById,
    getCityList,
    getLocationByLocation
};

export default LocationService;
