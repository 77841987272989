import React, { useEffect, useState } from 'react';
import { Card, Button, Row, Col } from 'react-bootstrap';
import ServicesService from '../../service/ServicesService';
import imageService from '../../service/ImageServie';

function AdCard({ advertisement, onViewDetails }) {
    const [services, setServices] = useState([]);
    const [showNumber, setShowNumber] = useState(false);

    useEffect(() => {
        const fetchServices = async () => {
            try {
                const serviceRequests = advertisement.serviceIds.map(id =>ServicesService.getServiceById(id));
                const serviceResponses = await Promise.all(serviceRequests);
                const serviceNames = serviceResponses.map(response => response.name);
                setServices(serviceNames);
            } catch (error) {
                console.error('Error fetching services:', error);
            }
        };

        if (advertisement.serviceIds && advertisement.serviceIds.length > 0) {
            fetchServices();
        }
    }, [advertisement.serviceIds]);

    const formatPhoneNumber = (phoneNumber) => {
        // Format phone number as (XXX) XXX-XXXX
        return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
      };

    return (<>
        <Card className="mb-2 internal_ads_row col-md-11 px-0"> 
            <Row className='mx-0 justify-content-center'>
            {advertisement.imageName && (
                <Col md={3} className='p-0 align-content-around ad_banner'>
                <Card.Img variant="top" 
                    src={`${imageService.getAdsImage}${advertisement.imageName}`}
                    alt={advertisement.companyName} 
                    />
                </Col> 
            )}
                <Col md={advertisement.imageName ? 7 : 10}>

                    <Card.Body className='p-0 planCard-style text-left'>
                          <Row>
                            <Col md={12} className='planInfo-style'>
                              <div className='row'>
                                <div className='col-md-6'> 
                                  <div className='cardText'> 
                                    <span className='ad_miles mb-0'>{advertisement.distance.toFixed(1)} miles</span> 
                                    <Card.Title>{advertisement.companyName}</Card.Title>
                                    <p className='ad_address mb-0'><i className="fas fa-location-dot"></i> {advertisement.city}, {advertisement.state}</p>
                                    <p className='ad_email mb-2'><i className="fa fa-envelope"></i> {advertisement.email}</p>
                                    {/* <p className='ad_website mb-2'>
                                      <i className="fa fa-globe"></i> <a href={advertisement.website} target="_blank" rel="noopener noreferrer">{advertisement.website}</a>
                                    </p> */}
                                  </div>

                                  <div className="ad_description">
                                    <p className='mb-0'>{advertisement.description}</p>
                                  </div>

                                </div>

                                <div className='col-md-6'>
                                  <div className='ad_services'>
                                    <h6 className='ad_worktype'>Work Type : <b>{advertisement.shopType}</b></h6>
                                    <ul className='ad_serviceList'>
                                    {services.slice(0, 4).map((service, index) => (
                                      <li key={index}>{service}</li>  
                                    ))}
                                    </ul>
                                  </div>
                                </div>

                              </div>
                            </Col>
                          </Row>
                        </Card.Body>
                </Col>

                <Col md={2} className='p-0'>  
                    <div className="planPrice-style text-center align-content-around"> 
                    <h4>For More Details </h4> 
                    <button className='btn showNumber' type="button" onClick={() => setShowNumber(true)}><i className="fa fa-phone"></i> {showNumber ? formatPhoneNumber(advertisement.phone) : "Call Us"}</button>
                    <h6 className='ad_hours'><i className="far fa-clock"></i> {advertisement.workingHours}</h6>
                    <Button className='planBtn' variant="primary" onClick={() => onViewDetails(advertisement.id, advertisement.companyName)}>View Detail</Button>
                    </div>
                </Col>

            </Row>
        </Card>

    </>

    );
}

export default AdCard;