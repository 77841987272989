import axios from 'axios';

//const API_URL = "http://localhost:8080/ors/api/plans"; 
const API_URL = "https://api.onlineroadservices.com/api/plans";

const getAllPlans = async () => {
    try {
        const response = await axios.get(API_URL);
        return response.data;
    } catch (error) {
        console.error('Error getting Plan:', error.response ? error.response.data : error.message);
        throw new Error(error.response ? error.response.data : 'Failed to getting Plan');
    }
};

const PlanService = {
    getAllPlans
};

export default PlanService;