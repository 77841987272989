
const ComponenetHeader=({name})=>{

    return(
        <section className="breadcrumb_section">
          <div className="container">
             <div className="row">
                <div className="col-md-12">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/home">Home</a></li>
                    <li className="breadcrumb-item active" aria-current="page">{name}</li> 
                  </ol>
                </nav>
                </div>
             </div>
          </div>
        </section>
    );
};

export default ComponenetHeader;