import React from "react";
import Mobileapp from '../../assets/mobile-app.png';
import playstore from '../../assets/googleplay.png';
import appstore from '../../assets/appstore.png';
import ComponenetHeader from "../common/ComponenetHeader";

const MobileAppLink = () =>{

    return(
        <>

        <ComponenetHeader
           name={'Mobile App'}
        />

        <section className="d_app_section text-left">
            <div className="container">
                <div className="row"> 
                    <div className="col-md-6">
                        <div className="app_screenshot text-center">
                         <img src={Mobileapp} className='img-fluid'/>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="app_content">
                            <h3>ORS Mobile App</h3>
                            <p>
                            ORS is the largest online directory service provider specializing in the trucking Industry. 
                            Our directory harbors a listing of most truck service companies including Truck Repair Shops, 
                            Trailer Repair Shops, Weigh Stations, Scales, Locksmiths, Truck Parts, Reefer Repair Shops, 
                            Insurance companies, Truck/Trailer Rentals, Truck Wash Locations and many more. ORS offer Motor 
                            Carrier Companies, their drivers & Truck Owner Operators a simple way to connect with potential 
                            truck repair service providers whenever they have a breakdown. Our customer 
                            service team is available 24/7/365 for any help in using our software application or help in 
                            finding Truck/Trailer/RV repair/breakdown service providers. Our network is multiplying every 
                            minute so we can provide you with the breakdown service providers in every city, state, and 
                            region of North America. Find nearby Mobile truck breakdown services on our "Online Road Service" 
                            mobile app.
                            </p>

                            <h5>Now Available on iOS and Android</h5>

                            <div className="app_btn_img d-flex">
                                <a href="https://apps.apple.com/in/app/online-road-service/id6444897798"><img src={appstore} className='img-fluid'/></a>
                                <a href="https://play.google.com/store/apps/details?id=com.ors.ors&pli=1"><img src={playstore} className='img-fluid'/></a>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </section>


        </>
    );
};

export default MobileAppLink;