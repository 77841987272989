import React from "react";
import ComponenetHeader from "../common/ComponenetHeader";

const RefundAndCancellation =()=>{

    return(<>
        <ComponenetHeader
           name={'Refund And Cancellation'}
        />

        <section className="content_page text-left"> 
        <div className="container">
            <div className="row content_page_style">
             <div className="col-md-12">
               <h2 className="mb-4">Refund And Cancellation Policy</h2>
               <h3>Cancellation Policy</h3>
               <ul>
                <li>A subscriber can place the cancellation order for a particular service within 15 days of service agreement.</li>
                <li>No cancellation is possible for the services offered with promotional discounts. These are limited edition offers and hence any range of refund would not be possible.</li>
                <li>Fee once paid will not be refunded in any case even if the cancellation is posted within 12 hours.</li>
                <li>A service stands cancel if next payment is not received within 7 working days</li>
               </ul>
             </div>

             <div className="col-md-12">
               <h3>Refund Policy</h3>
               <ul>
                <li>Refund request will be entertained within 15 days of purchase only.</li>
                <li>There is always a certain set of resources that are involved in achieving every milestone of a campaign. Thus, a refund would not be entertained for the work already completed.</li>
                <li>No refund is possible for the fee paid in the form of administrative fees or installation charges.</li>
                <li>Taxes paid on services levied by regulatory bodies would not be refunded with fee refund.</li>
                <li>No refund will be entertained in case service gets canceled due to any violation of terms stated in our Terms & Condition section.</li>
                <li>ORS is not liable to pay refund if delay or service disruption happens due to third party involvement.</li>
               </ul>
             </div>


            </div>
        </div>

        </section>
        </>
    );
};

export default RefundAndCancellation;