import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Spinner, Alert, Form } from "react-bootstrap";
import AdvertisementService from "../service/AdvertisementService";
import AdCard from "../component/advertisement/AdCard";
import LocationService from "../service/LocationService";
import AdListingHeader from "../component/common/AdListingHeader";
import imageService from "../service/ImageServie";
import CityLinks from "../component/common/CityLinks";

const AdListingPage = () => {
  const { subcategory, searchlocation } = useParams();  
  const formattedLoaction = searchlocation?.trim().replace(/-/g, ', ');

  const navigate = useNavigate();

  const subcategoryId = sessionStorage.getItem("subcategoryId");
  const shopType = sessionStorage.getItem("shopType");

  const [location, setLocation] = useState(null);
  const [ads, setAds] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortByDistance, setSortByDistance] = useState(false);

  const formatedSubcategory = subcategory.toString().trim().replace(/-/g, ' ');

    // Fetch Location when component mounts or location changes
    useEffect(() => {
      const fetchLocation = async () => {
        try {
          if (formattedLoaction) {
            const response = await LocationService.getLocationByLocation(formattedLoaction);
            setLocation(response); 
          } 
        } catch (error) {
          console.error('Error fetching location:', error.message);
        }
      };
  
      fetchLocation();
    }, [formattedLoaction]);

  useEffect(() => {
    const fetchAdvertisements = async () => {
    if (location) {      
    setLoading(true);
      try {        
        const params = {
          subcategoryId: subcategoryId || null, // Ensure subcategoryId is sent as null if not available
          city: location?.city?.name || null,
          state: location?.state?.name ||null,
          country: location?.country?.name || "United States",
          shopType: shopType || null,
          userLat: location?.latitude,
          userLon: location?.longitude,
          sortByDistance: sortByDistance
        };

        const data = await AdvertisementService.searchAdvertisements(params);
        setAds(data);     
        
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    }
    };  

    fetchAdvertisements();
  }, [subcategory, location, subcategoryId, sortByDistance, shopType]);

  const handleViewDetails = (adId, companyName) => {
    const CompanyName = companyName.toString().trim().replace(/\s+/g, '-');
    const url = `/details/${adId}/${CompanyName}/${searchlocation}`;
    
    // Open the URL in a new tab
    window.open(url, '_blank');
  };

  const handleSortByDistance = () => {
    setSortByDistance(!sortByDistance);
  };

  const handleCityClick =(selectedCity)=>{
    window.scrollTo(0, 0);
    
    const formattedCity = selectedCity.name.toString().trim().replace(/\s+/g, '_');
    const url = `/service-near-me/${subcategory}/${formattedCity}-${selectedCity.state.code}-${selectedCity.country.code}`;
    navigate(url);
  };

  return (<>

      <section className="listing_search">
      <div className="container">
      <AdListingHeader      
      initialLocation={location}
      initialSubcategoryId={subcategoryId}/>  
        </div>
      </section> 

      <section className="ad_listingView">
      <div className="saleAd-left d-md-block d-none">
        <a href="tel:3077760076"><img src={`${imageService.getSaleBanner}sale-ad-left.jpg`} className="img-fluid"/></a>
      </div>
 
      <div className="saleAd-right d-md-block d-none">  
        <a href="tel:3077760076"><img src={`${imageService.getSaleBanner}sale-ad-right.jpg`} className="img-fluid"/></a>
      </div> 

      <div className="container">
        <div className="row justify-content-center px-md-0 px-2">
         
      {loading && <Spinner animation="border" role="status" className="my-5"><span className="sr-only">Loading...</span></Spinner>}
      {error && <Alert variant="danger" className="my-5">Error: {error}</Alert>}
      
      {!loading && !error && ads.length > 0 ? (
        <>
         <Form.Check
              type="checkbox"
              id="sortByDistance"
              label="Sort by Distance"
              checked={sortByDistance}
              onChange={(e) => handleSortByDistance(e.target.checked)}
              className="mt-3 mb-2 text-right px-0 px-md-3 col-md-11"
            />
          {ads.map(ad => (
            <AdCard
              key={ad.id} 
              advertisement={ad} 
              onViewDetails={handleViewDetails} 
            />
          ))}
        </>
      ) : (
        <h5 className="my-5 py-5">No advertisements found for {formatedSubcategory} in {location?.city?.name} {location?.state?.name || 'United States'}</h5>
      )}

      </div>
      </div>
     </section> 

    <section className="service_nearby">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
          <h4 className="nearby_title">{formatedSubcategory} Service near me in {location?.state?.name || 'United States'}</h4>
          </div>

          <div className="col-md-12">
            <div className="service_nearby_list">
            <CityLinks
            subcategory={formatedSubcategory}
            countryId={location?.country?.countryid || '1'}
            stateId={location?.state?.stateid || '5'}
            keyword='Service near me in'
            handleCityClick={(city) => handleCityClick(city)}
      />
            </div>
          </div>
      </div>
     </div>
    </section>

    <section className="roadside_nearby">
      <div className="container">
      <div className="row">
         <div className="col-md-12">
         <h4 className="roadside_title">Roadside Assistance near me in {location?.state?.name || 'United States'}</h4>
        </div>

        <div className="col-md-12">
            <div className="roadside_nearby_list"> 
            <CityLinks
              countryId={location?.country?.countryid || '1'}
              stateId={location?.state?.stateid || '5'}
              keyword='Roadside Assistance near me in'
              handleCityClick={(city) => handleCityClick(city)}
      />
            </div>
          </div>
       </div>
       </div>
    </section>

</>

  );
};

export default AdListingPage;
