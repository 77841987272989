import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import './App.css';
import HomePage from './view/HomePage';
import Header from './component/common/Header';
import Footer from './component/common/Footer';
import AdListingPage from './view/AdListingPage';
import Plans from './component/plan/Plans';
// import BlogPage from './view/BlogPage';
import AboutUs from './component/aboutUs/AboutUs';
import ContactUs from './component/contactUs/ContactUs';
import PrivacyPolicy from './component/privacyPolicy/PrivacyPolicy';
import TermsAndCondition from './component/termsAndCondition/TermsAndConditon';
import RefundAndCancellation from './component/refundAndCancellation/RefundAndCancellation';
import AdDetailsPage from './view/AdDetailsPage';
import NotFound from './view/NotFound';
import MobileAppLink from './component/mobile-app-link/MobileAppLink';

function App() {
  return (
    <div className="App">
      <Header />
            <Router>
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/home" element={<HomePage />} />
                    <Route path="/plans" element={<Plans />} />
                    <Route path="/service-near-me/:subcategory/:searchlocation" element={<AdListingPage />} /> 
                    <Route path="/service-near-me/:subcategory" element={<AdListingPage />} />
                    <Route path="/details/:adId/:CompanyName/:searchlocation" element={<AdDetailsPage />} />                    
                    <Route path="/about" element={<AboutUs />} />
                    <Route path="/contact" element={<ContactUs />} />
                    <Route path="/app" element={<MobileAppLink />} />
                    <Route path="/privacypolicy" element={<PrivacyPolicy />} />
                    <Route path="/termsandcondition" element={<TermsAndCondition />} />
                    <Route path="/refundandcancellation" element={<RefundAndCancellation />} />                     
                    {/* <Route path="/blog" element={<BlogPage />} />           */}
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </Router>      
      <Footer />
    </div>
  );
}

export default App;
