//const API_URL = "http://localhost:8080/ors/";
const API_URL = "https://api.onlineroadservices.com/";

const getPlanImage = API_URL+'image/plansimage/';

const getAdsImage = API_URL+'image/adsimage/';

const getSaleBanner = API_URL+'image/salebanner/';

const imageService ={
 getPlanImage,
 getAdsImage,
 getSaleBanner

};

export default imageService