import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const Footer = () => {
  return (
    <footer className="bg-dark text-white pt-5 pb-2"> 
      <Container>
        <Row>
          <Col className='foot_text my-3' md={12}>
            <ul>
              <li><a href="/home" className="text-white">Home</a></li>
              <li><a href="/plans" className="text-white">Plans</a></li>
              <li><a href="/about" className="text-white">About Us</a></li>
              <li><a href="/contact" className="text-white">Contact Us</a></li>
              {/* <li><a href="/blog" className="text-white">Blog</a></li> */}
              <li><a href="/privacypolicy" className="text-white">Privacy Policy</a></li>
              <li><a href="/termsandcondition" className="text-white">Terms And Conditions</a></li>
              <li><a href="/refundandcancellation" className="text-white">Refund And Cancellation</a></li>
            </ul>
          </Col>

          <Col className='contact-detail my-md-3' md={12}>
          <ul className="contact-list">
            <li><a href="tel:+13077760076"><i className="fa fa-phone"></i> 307-776-0076</a></li>
            <li><a href="mailto:info@onlineroadservices.com"><i className="fa fa-envelope"></i> info@onlineroadservices.com</a></li>
          </ul>

          </Col>

          <Col className='social-icon mt-2' md={12}> 
          <ul>
          <li><h6 className="text-white text-uppercase">Follow Us :</h6></li>   
          <li><a href="https://www.facebook.com/onlineroadservices" target="_blank"><i className="fab fa-facebook-square"></i></a></li>
          <li><a href="https://www.instagram.com/onlineroadservices/" target="_blank"><i className="fab fa-instagram"></i></a></li>
          <li><a href="https://www.linkedin.com/company/online-road-services/about/?viewAsMember=true" target="_blank"><i className="fab fa-linkedin"></i></a></li>
          </ul>
          </Col>
          <Col md={12}>
          <hr/>
          </Col>

          <Col md={12} className="text-center mt-2">
            <p>&copy; {new Date().getFullYear()} OnlineRoadService. All Rights Reserved.</p>
          </Col>

        </Row>
       
      </Container>
    </footer>
  );
};

export default Footer;
