import React from "react";
import Plans from "../component/plan/Plans";
import SearchSection from "../component/search/SearchSection";
import AboutUs from "../component/aboutUs/AboutUs";

const HomePage =()=>{

    return(
        <div className="main_content">  
            <SearchSection/>
            <AboutUs/>
            <Plans/>
        </div>
    );
};

export default HomePage;